<style src="../css/head.css"></style>

<style src="../css/detail.css"></style>

<template>
  <div>
    <Head-section></Head-section>
    <div v-if="iszh" id="bg_cn"></div>
    <div v-else id="bg_en"></div>
    <!-- <Foot-section></Foot-section> -->
  </div>
</template>
<script>
import HeadSection from "./HeadSection.vue";
import { mapGetters } from "vuex";
export default {
  name: "DreamtimeHome",
  components: {
    HeadSection,
    // FootSection,
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "keywords",
          content: "Dream time",
        },
      ],
    };
  },
  data() {
    return {
      
    };
  },
  computed: {
    ...mapGetters([
      "iszh"
    ])
  },
};
</script>

<style>
#bg_cn{
    background:url("../assets/background_cn.jpg") no-repeat; 
    width: 100%;
    background-size: 100%; 
    min-height: 923vw;
    }
#bg_en{
    background:url("../assets/background_en.jpg") no-repeat; 
    width: 100%;
    background-size: 100%; 
    min-height: 1396vw;
    }
</style>